import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

/*
Submit your sitemap to Google
https://developers.google.com/search/docs/advanced/sitemaps/build-sitemap#addsitemap

Google doesn't check a sitemap every time a site is crawled;
a sitemap is checked only the first time that we notice it,
and thereafter only when you ping us to let us know that it's changed.
Alert Google about a sitemap only when it's new or updated;
don't submit or ping unchanged sitemaps multiple times

> https://www.google.com/ping?sitemap=https://www.example.com/sitemap.xml


https://github.com/joshbuchea/HEAD
https://css-tricks.com/essential-meta-tags-social-media/
https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML/The_head_metadata_in_HTML
https://developers.google.com/search/docs/advanced/crawling/special-tags?hl=en

https://www.gatsbyjs.com/docs/add-page-metadata/
https://www.gatsbyjs.com/docs/add-seo-component/
https://www.gatsbyjs.com/docs/how-to/adding-common-features/seo/
https://www.gatsbyjs.com/tutorial/seo-and-social-sharing-cards-tutorial/#using-the-seo-component
TODO: https://developers.google.com/search/docs/advanced/appearance/overview
*/

/*
Icons are configured in gatsby-config.js:
https://www.gatsbyjs.com/plugins/gatsby-plugin-manifest/#configure-icons-and-their-generations---required
*/

const SEO = ({ title, description, lang, image, article, meta }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultLang,
    siteUrl,
    defaultImage,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    lang: lang || defaultLang,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  const ogType = article ? "article" : "website";

  return (
    // https://github.com/nfl/react-helmet#reference-guide
    <Helmet
      title={seo.title}
      titleTemplate={title ? titleTemplate : null}
      htmlAttributes={{ lang: seo.lang }}
      meta={[{ charset: "utf-8" }].concat(meta)}
    >
      <meta name="description" content={seo.description} />

      {/* Facebook Open Graph */}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content={ogType} />}

      {/* Twitter Card */}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.url && <meta property="twitter:url" content={seo.url} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  meta: PropTypes.array,
};

SEO.defaultProps = {
  title: null,
  description: null,
  lang: null,
  image: null,
  article: false,
  meta: [],
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultLang: lang
        siteUrl
        defaultImage: image
      }
    }
  }
`;
